import { InstagramIcon, Linkedin02Icon } from "@hugeicons/react-pro";
import { FaTiktok } from "react-icons/fa";

export interface SocialLink {
  href: string;
  ariaLabel: string;
  Icon: React.ComponentType<{ size: number; color: string }>;
}

export const socialLinks: SocialLink[] = [
  // {
  //   href: "https://www.instagram.com/neutureai/",
  //   ariaLabel: "Instagram",
  //   Icon: InstagramIcon as React.ComponentType<{ size: number; color: string }>,
  // },
  {
    href: "https://www.linkedin.com/company/neuture-ai/",
    ariaLabel: "LinkedIn",
    Icon: Linkedin02Icon as React.ComponentType<{
      size: number;
      color: string;
    }>,
  },
  // {
  //   href: "https://www.tiktok.com/@neuture.ai",
  //   ariaLabel: "TikTok",
  //   Icon: FaTiktok,
  // },
];
