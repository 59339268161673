"use client";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useModalStore } from "@/store/modal";
import { socialLinks } from "@/common/socialLinks"; // Importiamo i link

export const ProModal = () => {
  const isOpen = useModalStore((state) => state.isOpen("pro"));
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          closeModal("pro");
        }
      }}
    >
      <DialogContent className="p-6">
        <DialogHeader>
          <DialogTitle className="text-center  text-xl font-semibold text-gray-800">
            Hai raggiunto il limite di messaggi utilizzabili
          </DialogTitle>
          <DialogDescription className="mt-2 text-center text-lg  text-gray-600">
            Se desideri continuare a utilizzare il servizio, contattaci su uno
            dei seguenti canali social
          </DialogDescription>
        </DialogHeader>
        <div className="mt-4 flex justify-center gap-6">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={link.ariaLabel}
              className="transform transition-transform hover:scale-110"
            >
              <link.Icon size={32} color="#0C1235" />
            </a>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
