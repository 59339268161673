import { create } from "zustand";
import { ModalStore, ModalType } from "./types";

export const useModalStore = create<ModalStore>((set, get) => ({
  openModals: new Set(),
  openModal: (modalType) =>
    set((state) => ({
      openModals: new Set(state.openModals).add(modalType),
    })),
  closeModal: (modalType) =>
    set((state) => {
      const newOpenModals = new Set(state.openModals);
      newOpenModals.delete(modalType);
      return { openModals: newOpenModals };
    }),
  isOpen: (modalType) => get().openModals.has(modalType),
}));
