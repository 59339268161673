"use client";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useModalStore } from "@/store/modal";

import { Mining02Icon } from "@hugeicons/react-pro";

export const UnderConstructionModal = () => {
  const isOpen = useModalStore((state) => state.isOpen("under_construction"));
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          closeModal("under_construction");
        }
      }}
    >
      <DialogContent className="w-[90%] rounded-md sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-[#0C1235]">
            <Mining02Icon color="#0C1235" /> Under Testing
          </DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p className="text-sm text-gray-500">
            The &ldquo;dedicated to me&rdquo; feature will shortly be released,
            stay tuned!
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};
